

.card__main{
    position: relative;
    border-radius:30px;
    overflow:hidden;
    height:430px;
    width:93%;
    margin:30px auto;
    background-color:#FFFFFF;
    box-shadow: 0px 20px 25px 10px rgba(0,0,0,0.1);
    // box-shadow: 0px 7px 10px rgba(0,0,0,0.1);
    transition: all 0.5s ease;

    h6{
        margin-left:100px;
        text-transform:capitalize;
        margin-top:15px !important;
        font-size: 1.2em !important;
        font-family:'Ramaraja';
        font-weight: 500;
        color: lighten( $primary, 10%);
        overflow: hidden;
        text-overflow: ellipsis;
        }

    &:hover {
        height: 440px;
        width: 95%;
        margin: 25px auto;
        box-shadow: 0px 20px 25px 10px rgba(0,0,0,0.3);
    }
}

.card__footer {
    position: absolute;
    width: 100%;
    bottom: 0;
}

.card__movie-img{
    padding:10px 10px;
    position:relative;
    }
    
.card__backimg{
    height:260px;
    width:100%;
    border-radius: 30px 30px 10px 30px;
    }

.card__movie-cover{
    position:absolute;
    height:120px;
    bottom:-62px;
    left:32px;
    border-radius:5px;
    box-shadow: 0px 0px 20px rgba(0,0,0,0.1);
    }

.card__fa-cart{
    display: flex;
    justify-content: center;
    align-items: center;
    position:absolute;
    right:28px;
    bottom:-7px;
    width:40px;
    height:40px;
    text-align:center;
    line-height:40px;
    font-size:13px;
    background-color:lighten(#e41c19, 15);
    color:#fff;
    border-radius:60px;
    box-shadow: 0px 7px 10px rgba(0,0,0,0.3);
    }
    
.card__rating{
    height:10px;
    margin-left:130px;
    margin-top:0px;
    }
    
.card__head1{
    display: flex;
    justify-content: space-between;
    column-count:4;
    height:30px;
    padding:0px 26px;
    width:100%;
    margin-top:10px;
   
    p{
    color:rgba(0,0,0,0.7);
    font-family:sans-serif;
    font-size:0.9em;
    }
}
    
.card__head2{
    display: flex;
    justify-content: space-between;
    column-count:4;
    height:30px;
    padding:0px 26px;
    width:100%;
    
    p{
    color:rgba(0,0,0,0.7);
    font-family:sans-serif;
    font-size:0.9em;
    font-weight:700;
    }
}

.card_prices-price {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;    
    bottom: 7px !important;
    left: 20px;
    background: linear-gradient( to bottom, rgb(100, 142, 206) 0%, rgb(100, 145, 212) 50%, rgb(57, 119, 211) 75%, rgb(50, 114, 209) 100%);
    border-radius: 5px;
    width: 6em !important;
    color: white;
}

.card__description{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.75em;
    margin: 0px 10px;
    color:rgb(105, 107, 109);
}

.card__price-total-contenedor {
    display: flex;
    // background: linear-gradient( to bottom, rgb(100, 142, 206) 0%, rgb(100, 145, 212) 50%, rgb(57, 119, 211) 75%, rgb(50, 114, 209) 100%);
    border-radius: 5px;
    color: white;
}

.card__price-total {
    margin-top: 0.2em;
    font-size: 0.9em;
    font-weight: 700;
    margin-left: 10px;
    color: white;
    background-color: $primary;
    padding: 0.2em;
    border-radius: 0.4em;
}