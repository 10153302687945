.navbar__navbar {
    background-color: $primary;
    color: white !important;
}

.navbar__title {
    color: white !important;
}

.navbar-nav .nav-link {
    color: white !important;
}

.navbar__header-user-name{
    font-family: 'Ramaraja', serif;
    font-size: 1.5rem;
    font-weight: 500;
    color: lighten($primary,30);
}

.dropdown-item {
    color: white !important;

    &:hover {
        background-color: lighten($primary,10) !important;
    }
}

.dropdown-menu{
    background-color: $primary !important;
}

.navbar__dolar {
    color: lighten($primary,40);
}