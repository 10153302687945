

.auth__container {
    // background-color: $primary;
    background: linear-gradient($primary, $primary, lighten($primary, 20%));
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.auth__box-content {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 5px 5px 10px 5px rgba(15, 15, 15, 0.25);
    // height: 75%;
}

.auth_login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    text-align: center;
    // height: 100%;
}

.auth__title {
    margin-top: 0.75em;
    font-family: 'Ramaraja', serif;
    font-size: 3.5rem;
    color: lighten($primary, 10%);

}

.auth__divisor {
    width: 100%;
    height: 2px;
    color: lighten($primary, 20%);
}

.auth__form {
    padding: 1.5em;
}

.auth__input-icon {
    color: $primary;
}

.auth__input-wsp{
    margin-bottom: 1em !important;
}
    
.auth__input-placeholder {

    &::placeholder {
        font-size: 0.85em;
    }
}

.auth__alert-error{
    background-color: rgb(238, 89, 89);
    border-radius: 5px;
    color: white;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    padding: 5px;
}

.auth__forget-container {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    .auth__forget-title{
        background-color: $primary;
        width: 100%;
        margin-bottom: 3px;

        p{
            text-align: center;
            color: white;
            margin: 2px 0px;
            font-size: 1.2em;
        };
    }

    .auth__forget-content {
        flex-direction: column;
        width: 80%;
        max-width: 30em;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: lighten($primary, 40);       
    }
}