
@import "./base/base";
@import "./base/custom";

@import "./components/auth";
@import "./components/productos";
@import "./components/usuarios";
@import "./components/useradmin";
@import "./components/components";
@import "./components/navbar";
@import "./components/card";
@import "./components/cart";
@import "./components/list";
@import "./components/order";
@import "./components/order-detail";
@import "./components/detail-product";
@import "./components/estadisticas.scss";
@import './components/home.scss';
@import './components/comunication.scss';
@import "../../node_modules/bootstrap/scss/bootstrap";

// fonts
@import url('https://fonts.googleapis.com/css2?family=Ramaraja&display=swap');