.table-b > thead > tr > th,
.table-b > tbody > tr,
.table-b > tbody > tr {
    border-bottom: 1px solid rgb(145, 142, 142) !important;
}

.order__table-head {
    font-weight: 400;
}

.order__action-order {
    color: $primary;
    margin-top: 0.5em;    
    text-align: center;
}

.react-datepicker-wrapper {

    cursor: pointer !important;

    .react-datepicker__input-container{
        border: none;
    }
    
    input {
        border: 0.5px solid lighten($primary,30);
        border-radius: 2.5em;
        padding: 7.5px 12px !important;
    }
}

.order__print-total-negrita {
    font-weight: 600;
}