
$primary:  #445384;

html, body {
    height: 100vh;
    width: 100vw;
}

@media print{

    body, html {
        margin: 0;
        height: 100%;
        overflow: visible !important;   
    }

    html {
        overflow: hidden !important;
      }

    .no-print {
        display: none !important;
        box-shadow: none !important;

        * {
            display: none !important;
            box-shadow: none !important;
        }
    }

    .print {
        display: block !important;
        overflow: visible !important;
        height: 100% !important;
    }

    @page {
        size: 210mmn 297mm;
        margin: 10mm 10mm 10mm 10mm;
        header: 'FIJAMOM SA';
      }

    div.saltopagina{
        display: block !important;
        page-break-after: always !important;
        margin-top: 2em;
    }

    body #ModalEtiqueta{        
        @page {
          size: 8cm 10cm;
          margin: 0mm 0mm 0mm 0mm;
        }
    }

    .modal, #ModalEtiqueta{
        overflow: hidden !important;
        box-sizing: border-box;
        margin: 0 !important;
    }

    * {
        border: none !important;        
        // overflow-y: hidden;
      }
}


@page {
    size: 210mmn 297mm;
    margin: 10mm 10mm 10mm 10mm;
    header: 'FIJAMOM SA';
  }

@media all {
    div.saltopagina{
        display: none;
    }
}


.pointer {
    cursor: pointer;
}

.modal-dialog {
    max-width: 90% !important;
}

.modal-header {
    background: #667299;
    color: white;
 }

.w10 {
    width: 10%;
}

.w20 {
    width: 20%;
}

.w25{
    width: 25%;
}

.w50 {
    width: 50%;
}

.w75{
    width: 75%;
}


.map-container {
    height: 400px;
    }

.dropdown-toggle {
    width: 100%;
}

.dropdown-toggle
.dropdown-menu {
    width: 100%;    
}

.blur {
    filter: blur(5px);
}

.badge {
    font-size: 0.8rem;
    vertical-align: top;
  }

.unread-badge {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 3px 7px;
    font-size: 12px;
}