
.productos__title {
    margin-left: 10px;
    margin-top: 1em;
    font-family: 'Ramaraja', serif;
    font-size: 2.5rem;
    font-weight: 500;
    color: $primary;
}

.products__photo-content {
    padding: 10px;
}

.products__add-title {
    font-family: 'Roboto', serif;
    margin-right: 1em;
    font-size: 1rem;
    font-weight: 500;
    color: darken($primary,10);
}

.products__listado-precios {
    font-family: 'Roboto', serif;
    margin-bottom: 1em;
    font-size: 1.2rem;
    font-weight: 500;
    color: darken($primary,10);
}

.product__botonera {
    width: 100%;
}

.product__botonera-select {
    color: darken($primary,10) !important;
    background-color: rgb(223, 221, 118) !important;
}

.row .col-md-3 {
    display: flex;
    justify-content: center;

    &:nth-child( n + 5) {
        .product__botonera {
            width: 60%;
        }
    }
    
}

.product__color-box {
    display: inline-block;
    width: 2em;
    height: 2em;
}