
.estadisticas__cbo-clientes {
    width: 100%;

    button{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .dropdown-menu{
        width: 100%;
    }
}

.estadisticas__text-total {
    font-size: 1.5em;
    font-weight: bold;
    background-color: lighten($primary, 10);
    padding: 0.2em 0.5em;
    color: white;
}

.estadisticas__totales-table {
    background-color: burlywood;
}