.welcome-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-height: 100vh;
    // background-color: #f7f7f7;
    background-color: $primary;
  }
  
.welcome-logo {
    width: 5em;
    display: flex;
    justify-content: center;
    align-items: center !important;
    margin-bottom: 30px;
}

.welcome-img {    
    width: 10em;
}

.welcome-content {
    text-align: center;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center !important;
}

.welcome-heading {
font-size: 32px;
color: #f5f4f4;
}

.welcome-text {
font-size: 18px;
color: #eeecec;
}

.options {
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 20px;
margin-top: 20px;
}

.action-button {
    margin: 1em 1em 1em 1em;
    padding: 10px 5px;
    background-color: white;
    color: $primary;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    height: 5em;
    width: 20em;
    cursor: pointer;

    position: relative; /* Añadimos posición relativa para que ::before sea relativo a este elemento */
    overflow: hidden; /* Ocultamos el contenido que pueda salir de los límites del botón */

    transition: background-color 0.5s ease, transform 0.5s ease, border 0.3s ease, color 0.3s ease; /* Agregamos transiciones */
}

.action-button::before {
    content: attr(data-text); /* Creamos un elemento pseudo */
    position: absolute; /* Posición absoluta para que quede dentro del botón */
    bottom: -100px; /* Comenzará desde abajo */
    left: 0;
    width: 80%;
    height: 0; /* Inicialmente oculto */
    background-color: lighten($primary, 10);
    transition: bottom 0.5s ease; /* Agregamos transición para la altura */
    margin: 10%;
  }

.action-button:hover {
    background-color: lighten($primary, 10);
    border: 2px solid lighten($primary, 10);
    color: white;
    color: lighten($primary, 10);
    transform: scale(1.05);
}

.action-button:hover::before {
    bottom: 2em; /* Al hacer hover, aparecerá desde abajo */
    background-color: lighten($primary, 10);
    font-size: 11px;
    color: white;
    transform: scale(1.05);
  }

.contact-info {
    text-align: center;
    margin-top: 20px;
    font-size: 14px;
    color: #eee9e9;

    a {
        color: white;
    }
}