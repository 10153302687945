

.list__main{
    display: flex;
    flex-direction: row;
    position: relative;
    border-radius:30px;
    overflow-y:auto;
    min-height:10em;
    width:100%;
    margin:1em auto 1em auto;
    background-color:#FFFFFF;
    box-shadow: 0px 20px 25px 10px rgba(0,0,0,0.1);
    // box-shadow: 0px 7px 10px rgba(0,0,0,0.1);
    transition: all 0.5s ease;

    h6{
        margin-left:10px;
        text-transform:capitalize;
        margin-top:15px !important;
        font-size: 1.2em !important;
        font-family:'Ramaraja';
        font-weight: 500;
        color: lighten( $primary, 10%);
        overflow: hidden;
        text-overflow: ellipsis;
        }

    &:hover {
        height: 10.25em;
        width: 100%;
        margin-top: 0.5em;
        margin-bottom: 1.5em;
        box-shadow: 0px 20px 25px 10px rgba(0,0,0,0.3);
    }

    &::-webkit-scrollbar {
        // -webkit-appearance: none;
        display: none;
    }
    
    // &::-webkit-scrollbar:vertical {
    //     width:10px;
    // }
    
    // &::-webkit-scrollbar-button:increment,&::-webkit-scrollbar-button {
    //     display: none;
    // } 
    
    // &::-webkit-scrollbar:horizontal {
    //     height: 5px;
    // }
    
    // &::-webkit-scrollbar-thumb {
    //     background-color: #797979;
    //     border-radius: 20px;
    //     border: 2px solid #f1f2f3;
    // }
    
    // &::-webkit-scrollbar-track {
    //     border-radius: 10px;  
    // }
}

.list__movie-img{
    width: 30%;
    padding:2px 2px;
    position:relative;
    @media (max-width: 768px) {
        width: 70%;
    }
}

.list__backimg{
    height:10em;
    width:10em;
    border-radius: 30px 30px 30px 30px;
}

.list__prices-price {    
    display: inline-flex;
    justify-content: center;
    align-items: center;  
    bottom: 7px !important;
    left: 20px;
    background: linear-gradient( to bottom, rgb(100, 142, 206) 0%, rgb(100, 145, 212) 50%, rgb(57, 119, 211) 75%, rgb(50, 114, 209) 100%);
    border-radius: 5px;
    width: 6em !important;
    color: white;
}

.list__init-card {
    width: 40%;
}

.list__init-card-novedades {
    width: 70%;
}

.list__facturacion {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.list__botonera {
    display: flex;
    // flex: 1;
    margin-right: 0.5em;
    width: 5em !important;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: max-content;

    @media (max-width: 768px) {
        margin-right: 1em;
    }
}

.list__boton-add-sub {
    font-size: 1.8em;
    color: lighten( $primary, 10%);
}

.list__show-detail {
    padding-top: 0.2em;
    margin-left: 0.4em;
    color: lighten( $primary, 10%);
    font-size: 1.3em;
}

.list__show-detail-text {
    margin-left: 0.4em;
    font-size: 1em;
    font-family:'Ramaraja';
    font-weight: 500;
    color: lighten( $primary, 10%);
}

.list__facturacion {
    font-size: 1em;
    color: lighten( $primary, 10%);
    margin-bottom: 0.5em;
}

