
.cart__main {
    position: relative;
    border-radius:30px;
    overflow:hidden;
    // height:430px;
    width:100%;
    margin:10px auto;
    padding: 30px 20px;
    background-color:#FFFFFF;
    box-shadow: 0px 20px 25px 10px rgba(0,0,0,0.1);
    // box-shadow: 0px 7px 10px rgba(0,0,0,0.1);
    transition: all 0.5s ease;
}

.cart__table{
    border-radius:30px !important;
}

.cart__header {
    display: flex;
    justify-content: space-between;
    margin: 0px 30px 0px 10px;
}

.cart__header-container{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin: 0px 30px 10px 10px;
}

.cart__header-icon{
    color: $primary;
    font-size: 1em;
    margin-right: 1em;
}

.cart__button {
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    
    &:hover * {
        color: white;
    }
}

.cart__title-total{
    margin: 0px 3em 1em 2em;
    font-size: 1.2em;
    background-color: lighten($primary, 20%);
    color: white;
    box-shadow: -1px 5px 5px 1px rgba(0,0,0,0.2);
}

.cart__product{
    margin: 0px 30px 0px 10px;
}

.cart__header-container-pendiente{
    display: flex; 
    justify-content: space-between;
}

.cart__title-total-pendiente{
    margin: 0px 1em 1em 2em;
    font-size: 1.2em;
    background-color: lighten($primary, 20%);
    color: white;
    box-shadow: -1px 5px 5px 1px rgba(0,0,0,0.2);
    padding: 0.3em;
}