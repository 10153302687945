
.order-detail__text-markets {
    font-family: Ramaraja;
    font-size: 2.0rem;
    color: darken($primary,10);
}

.order-detail__hr {
    width: 80%;
    text-align: center;
}

.order-detail__text-name {
    font-family: Ramaraja;
    font-size: 1.3rem;
    color: lighten($primary,10);

}

order-detail__text-name-small {
    font-family: Ramaraja;
    font-size: 0.7rem;
    color: lighten($primary,10);
}