
.comunicacion_chat-box {
    max-height: 500px;
    overflow-y: auto;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    background: #f9f9f9;
}

.comunicacion_chat-message {
    margin: 10px 0;
}

.comunicacion_vh-90 {
    height: calc(100vh - 6em);
    overflow: hidden;
}

.comunicacion_icon-color {
    color: $primary;
}