
.useradmin__name {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: $primary;
}

.useradmin__botonera {
  width: 100%;
}

.useradmin__botonera-danger {  
  background-color: darken(red,20) !important;;
}