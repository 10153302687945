

.components__table-header {
    background-color: $primary;
    color: white;
    height: 4em;
}

.components__small-box {
    border-radius: 0.25em;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
    display: block;
    margin-bottom: 1em;
    position: relative;
    height: 5em;
    width: 5em;
    color: white;
    background-color: lighten($primary,20);
    transition: background-color 0.2s ease-in-out;

    &:hover {
        background-color: lighten(#BFD416,30);
        color: $primary;
    }
}

.components__boton-action{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 2em;
    height: 2em;
    padding: 0.25em;
    margin: 0.5em;
    
    color: white;
    border-radius: 1em;

    &:hover {
        cursor: pointer;       
        color: $primary;
        width: 2.5em;
        height: 2.5em;
        border-radius: 1.25em;
        margin: 0em;
        box-shadow: 0px 1em 1em -0.5em rgb(0, 0, 0);
        // box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
    }
}

.components__boton-action-delete{
    background-color: red;
}

.components__boton-action-edit{
    background-color: #a8377d;
    &:hover {
        background-color: lighten(#a8377d,30);
    }
}

.components__boton-action-show{
    background-color: #8e0bc2;
    &:hover {
        background-color: lighten(#8e0bc2,40);
    }
}
