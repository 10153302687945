

$theme_colors:(
    "primary": #445384,
    "secondary": #639aaf,
    "tertiary": #ae63eb,
    "warning": #e4d617,
    "success": #5cc71f,
    "danger": #c05151,
    "info": #8e94cc,
    "dark": #252323,
);