
.detail-product__name-product{
    font-family: 'Ramaraja', cursive;
    font-size: 2rem;
    font-weight: 500;
    color: lighten($primary, 20%);
}

.detail-product__description{    
    font-size: 1rem;
    font-weight: 300;
    color: darken($primary, 10%);
}

.detail-product__quantity{
    font-size: 1rem;
    font-weight: 300;
    color: darken($primary, 10%);
}

.detail-product__price {
    font-size: 1.1rem;
    font-weight: 300;
    color: $primary;
    text-align: end;
}

.detail-product__button-print{
    width: 8em;
}